import { components, operations } from '@/definitions/openapi';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import { EmptyCarCardAttachmentItem, EmptyCarCardAttachmentsFilter, EmptyHumanCardAttachmentItem, EmptyHumanCardAttachmentsFilter } from './items';
import store from '../../index';
import { BaseItemStateModule } from '@/definitions/base.item.state.module';
import _ from '@/apps/common/lodash';
import { configureDynamicStoreModuleFactory } from '@/store/common';

export type IHumanCardAttachment = components['schemas']['HumanCardAttachment'];
export type IHumanCardAttachmentsFilter = operations['human_card_attachments_list']['parameters']['query'];
export type ICarCardAttachment = components['schemas']['CarCardAttachment'];
export type ICarCardAttachmentsFilter = operations['car_card_attachments_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class HumanCardAttachmentModule extends BaseItemsStateModule<IHumanCardAttachment, IHumanCardAttachmentsFilter> {
  name = 'human-card-attachments';
  routeName = '';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyHumanCardAttachmentItem, EmptyHumanCardAttachmentsFilter);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class HumanCardAttachmentItemModule extends BaseItemStateModule<IHumanCardAttachment> {
  name = 'human-card-attachments';
  routeName = '';

  constructor(options: RegisterOptions, requestHandler?: (payload: any) => Promise<any>) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyHumanCardAttachmentItem);
    this.setEmptyItemsState();
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class CarCardAttachmentModule extends BaseItemsStateModule<ICarCardAttachment, ICarCardAttachmentsFilter> {
  name = 'car-card-attachments';
  routeName = '';

  constructor(options: RegisterOptions) {
    super(options);
    this.init(EmptyCarCardAttachmentItem, EmptyCarCardAttachmentsFilter);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

@Module({ generateMutationSetters: true })
export class CarCardAttachmentItemModule extends BaseItemStateModule<ICarCardAttachment> {
  name = 'car-card-attachments';
  routeName = '';

  constructor(options: RegisterOptions, requestHandler?: (payload: any) => Promise<any>) {
    super(options);
    this.emptyItem = _.cloneDeep(EmptyCarCardAttachmentItem);
    this.setEmptyItemsState();
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}

export const createHumanCardAttachmentsModule = configureDynamicStoreModuleFactory('human-card-attachments', (store, name) => {
  return new HumanCardAttachmentModule({ store, name });
});

export const createCarCardAttachmentsModule = configureDynamicStoreModuleFactory('car-card-attachments', (store, name) => {
  return new CarCardAttachmentModule({ store, name });
});
