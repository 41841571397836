






































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { relationsModule, humanCardsModule, carCardsModule } from '@/store';
import { CardType } from '@/store/cards/cards';
import { IRelation } from '@/store/relations/relations';
import { RelationLinksModule } from '@/store/relations/relation-links';
import { DialogActionType } from '@/components/app/dialogs.vue';

@Component({
  name: 'RelationForm'
})
export default class RelationForm extends Vue {
  @Prop({ type: String, required: true })
  cardType!: CardType;

  @Prop({ type: Number, required: true })
  cardId!: number;

  @Prop({ type: String, required: true })
  cardName!: string;

  @Prop({ type: Number, default: null })
  relationLinkId!: number | null;

  @Prop({ type: Boolean, default: true })
  createMode!: boolean;

  relationLinksModule!: RelationLinksModule;
  cardTypes = [CardType.Human, CardType.Car];
  relationId: number | null = null;
  anotherCardId: number | null = null;
  isRelationNameMatched = false;

  formData = {
    relationName: '',
    relationLinkName: '',
    connectWithAnotherCard: false,
    anotherCardType: CardType.Human,
    anotherCardName: '',
    anotherCardRelationLinkName: ''
  };

  get isSaveButtonDisabled() {
    return this.createMode && !this.$hasPermission('ffsecurity.add_relation') && !this.isRelationNameMatched;
  }

  get formDataExtended() {
    return {
      ...this.formData,
      relationId: this.relationId,
      anotherCardId: this.anotherCardId
    };
  }

  get anotherCardTitle() {
    return this.anotherCardId ? `${this.formData.anotherCardName} #${this.anotherCardId}` : '';
  }

  get rules() {
    return this.$applyRuleMessages({
      relationName: [{ required: true, message: 'error.required.field', trigger: 'change' }],
      relationLinkName: [{ required: true, message: 'error.required.field', trigger: 'change' }],
      anotherCardType: [{ required: true, message: 'error.required.field', trigger: 'change' }],
      anotherCardId: [
        {
          required: true,
          message: 'error.required.field',
          trigger: 'change',
          validator: function (rule, value, cb) {
            const result = value ? undefined : rule.message;
            cb(result);
          }
        }
      ],
      anotherCardRelationLinkName: [{ required: true, message: 'error.required.field', trigger: 'change' }]
    });
  }

  async filterRelations(value, callback) {
    try {
      const items = await relationsModule.getByFilter({ name_contains: value });
      const res = items.find((item) => item.name === value);
      this.isRelationNameMatched = !!res;
      callback(items.map((item) => Object.seal({ id: item.id, value: item.name })));
    } catch (e) {
      callback([]);
    }
  }

  selectRelation({ id, value }) {
    this.relationId = id;
    this.formData.relationName = value;
    this.isRelationNameMatched = true;
  }

  get anotherCardModule() {
    switch (this.formData.anotherCardType) {
      case CardType.Human:
        return humanCardsModule;
      case CardType.Car:
        return carCardsModule;
    }
    return humanCardsModule;
  }

  async filterCards(value, callback) {
    try {
      const items = await this.anotherCardModule.getByFilter({ name_contains: value });
      callback(items.map((item) => Object.seal({ id: item.id, value: item.name })));
    } catch (e) {
      callback([]);
    }
  }

  selectCard({ id, value }) {
    this.anotherCardId = id;
    this.formData.anotherCardName = value;
  }

  clearCard() {
    this.anotherCardId = null;
    this.formData.anotherCardName = '';
  }

  validateForm() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate((valid) => {
        if (valid) {
          resolve(true);
        } else {
          reject();
        }
      });
    });
  }

  async saveHandler() {
    try {
      await this.validateForm();

      if (this.relationId === null) {
        const relation = await relationsModule.create({ id: this.relationId, name: this.formData.relationName });
        this.relationId = relation.id;
      }

      this.relationLinksModule.setCardType(this.cardType);
      if (this.relationLinkId === null) {
        const relationLink = await this.relationLinksModule.create({
          card: this.cardId,
          relation: this.relationId,
          name: this.formData.relationLinkName
        });
      } else {
        const relationLink = await this.relationLinksModule.update({
          card: this.cardId,
          relation: this.relationId,
          name: this.formData.relationLinkName,
          id: this.relationLinkId
        });
      }

      if (this.formData.connectWithAnotherCard) {
        this.relationLinksModule.setCardType(this.formData.anotherCardType);
        const relationLink = await this.relationLinksModule.create({
          card: this.anotherCardId,
          relation: this.relationId,
          name: this.formData.anotherCardRelationLinkName
        });
      }
      this.$emit('action', DialogActionType.Reload);
      this.$emit('close');
    } catch (e) {
      console.error(e);
    }
  }

  cancelHandler() {
    this.$emit('close');
  }

  created() {
    this.relationLinksModule = new RelationLinksModule({ store: this.$store, name: 'module_' + Math.random() });
  }
  async mounted() {
    this.relationLinksModule.setCardType(this.cardType);
    if (this.relationLinkId) {
      const relationLinks = await this.relationLinksModule.getByFilter({ id_in: [this.relationLinkId] });
      const relation = relationLinks[0].relation as IRelation;
      this.relationId = relation.id;
      this.formData.relationName = relation.name;
      this.formData.relationLinkName = relationLinks[0].name;
    }
  }
  beforeDestroy() {
    this.relationLinksModule.destroy();
  }
}
