import { ICarCardAttachmentsFilter, ICarCardAttachment, IHumanCardAttachmentsFilter, IHumanCardAttachment } from '@/store/cards/card-attachments/index';

export const EmptyHumanCardAttachmentItem: IHumanCardAttachment = {
  id: 0,
  created_date: '',
  name: '',
  comment: '',
  file: '',
  card: 0,
  size: 0
};

export const EmptyHumanCardAttachmentsFilter: IHumanCardAttachmentsFilter = {
  limit: 10,
  card: undefined
};

export const mockHumanCardAttachmentItems: IHumanCardAttachment[] = [
  {
    ...EmptyHumanCardAttachmentItem,
    id: 1,
    card: 1,
    name: 'The One',
    file: 'test.txt',
    size: 1000234
  },
  {
    ...EmptyHumanCardAttachmentItem,
    id: 2,
    card: 2,
    name: 'The Two',
    file: 'test2.txt',
    size: 20002
  }
];

export const EmptyCarCardAttachmentItem: ICarCardAttachment = {
  id: 0,
  created_date: '',
  name: '',
  comment: '',
  file: '',
  card: 0,
  size: 0
};

export const EmptyCarCardAttachmentsFilter: ICarCardAttachmentsFilter = {
  limit: 10,
  card: undefined
};

export const mockCarCardAttachmentItems: IHumanCardAttachment[] = [
  {
    ...EmptyHumanCardAttachmentItem,
    id: 1,
    card: 1,
    name: 'The One',
    file: 'test.txt',
    size: 1000234
  },
  {
    ...EmptyHumanCardAttachmentItem,
    id: 2,
    card: 2,
    name: 'The Two',
    file: 'test2.txt',
    size: 20002
  }
];
