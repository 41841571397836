





















import ObjectThumbnail from '@/components/objects/thumbnail.vue';
import { ImageType } from '@/store/actions/showImageByType';
import { IBodyClusterEvent, ICarClusterEvent, IFaceClusterEvent, selectClusterEventsListStoreModuleFactory } from '@/store/clusters';
import { ObjectsType } from '@/store/objects/get.module';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'card-cluster-images',
  components: { ObjectThumbnail }
})
export default class CardClusterImages extends Vue {
  @Prop({ required: true })
  clusterId!: number | null;
  @Prop({ required: true, default: ObjectsType.Faces })
  objectsType!: string;

  private readonly module = selectClusterEventsListStoreModuleFactory(this.objectsType)(this.$store);
  loadingIds: number[] = [];

  get state() {
    return this.module.state;
  }

  get clusterPath() {
    return `#/clusters/${this.objectsType}/filter/id_in=${this.clusterId}&limit=10&ordering=-id`;
  }

  get clusterPathText() {
    return `${this.$tf(this.objectsType)} ${this.$tf('cluster,,1').toLowerCase()} ${this.$tf('id')}: ${this.clusterId}`;
  }

  mounted() {
    /** @todo Remove this hack as soon as possible. */
    this.state.filter.current = { cluster_in: this.clusterId as any, limit: 1e3 };
    if (this.clusterId) this.state.get();
  }

  beforeDestroy() {
    this.module.unreg();
  }

  previewHandler(item: IFaceClusterEvent | IBodyClusterEvent | ICarClusterEvent) {
    this.$store.dispatch('showImageByType', { type: ImageType.ClusterEvent, objectsType: this.objectsType, data: item });
  }

  deleteHandler(item: IFaceClusterEvent | IBodyClusterEvent | ICarClusterEvent) {
    this.loadingIds.push(item.id);
    this.state
      .delete(item.id)
      .then(() => {
        this.state.get();
        this.$showSuccess({ message: this.$tf('successful_action') });
      })
      .catch((e) => {
        this.$showError(e);
      })
      .finally(() => {
        const index = this.loadingIds.findIndex(id => id === item.id);
        if (index !== -1) this.loadingIds.splice(index, 1);
      });
  }
}
