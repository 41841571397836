import { IFormSchema } from '@/definitions/base.form.schema';

const schema: IFormSchema = {
  fields: [
    {
      prop: 'name',
      i18nLabel: 'name',
      component: {
        name: 'input'
      },
      validators: [
        {
          required: true,
          props: {
            i18nMessage: 'error.required.field'
          }
        }
      ]
    },
    {
      prop: 'comment',
      i18nLabel: 'comment',
      component: {
        name: 'input'
      }
    },
    {
      prop: 'watch_lists',
      i18nLabel: 'watch_lists',
      component: {
        name: 'watch-lists',
        i18nPlaceholder: 'watch_lists',
        itemsPath: '$store.state.watch_lists.items',
        excludeUnmatched: true
      },
      validators: [
        {
          required: true,
          name: 'array',
          props: {
            i18nMessage: 'error.array.empty',
            minLength: 1
          }
        }
      ]
    },
    {
      prop: 'created_date',
      i18nLabel: 'was_created,,2',
      component: {
        name: 'date-field'
      }
    },
    {
      prop: 'modified_date',
      i18nLabel: 'was_updated,,2',
      component: {
        name: 'date-field'
      }
    },
    {
      prop: 'active',
      component: {
        name: 'checkbox-group',
        items: [{ value: '', i18nLabel: 'active,,1' }]
      }
    }
  ]
};

export default schema;
