






























import { RelationLinksModule } from '@/store/relations/relation-links';
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import TableNavigation from '@/components/tables/navigation.vue';
import TableGenerator, { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import RelationForm from '@/components/cards/relations/relation-form.vue';
import { CardType } from '@/store/cards/cards';
import { DialogActionType } from '@/components/app/dialogs.vue';

@Component({
  components: { TableGenerator, TableNavigation }
})
export default class RelationList extends Vue {
  @Prop({ type: String, required: true })
  cardType!: CardType;

  @Prop({ type: Number, required: true })
  cardId!: number;

  @Prop({ type: String, required: true })
  cardName!: string;

  relationLinksModule!: RelationLinksModule;

  get state(): RelationLinksModule {
    return this.relationLinksModule;
  }

  get schema(): ITableColumn[] {
    return [
      { label_i18n: 'id', prop: 'id', width: 80 },
      { label_i18n: 'relation_name', formatter: (row) => row.relation.name },
      { label_i18n: 'relation_link', prop: 'name' },
      { label_i18n: 'created', prop: 'created_date', component: TableComponentNames.DateTime, width: 250 }
    ];
  }

  get selectedItems() {
    return this.relationLinksModule.selectedItems;
  }

  actionHandler(data) {
    if (data === DialogActionType.Reload) {
      this.relationLinksModule.get();
    }
  }

  createConnectionHandler() {
    this.$store.dispatch('showCustomDialog', {
      title: this.$tf('create_connection'),
      component: RelationForm,
      props: { cardType: this.cardType, cardId: this.cardId, cardName: this.cardName, createMode: true },
      actionHandler: this.actionHandler
    });
  }

  rowClickHandler({ item }) {
    this.$store.dispatch('showCustomDialog', {
      title: this.$tf('edit_connection'),
      component: RelationForm,
      props: { cardType: this.cardType, cardId: this.cardId, cardName: this.cardName, relationLinkId: item.id, createMode: false },
      actionHandler: this.actionHandler
    });
  }

  async deleteConnectionsHandler() {
    for (let item of this.selectedItems) {
      try {
        await this.relationLinksModule.delete(item.id);
      } catch (e) {
        console.warn('delete.error ', e);
      }
    }
    await this.relationLinksModule.get();
  }

  created() {
    this.relationLinksModule = new RelationLinksModule({ store: this.$store, name: 'module_' + Math.random() });
    this.relationLinksModule.setCardType(this.cardType);
    this.relationLinksModule.filter.current.card = this.cardId;
  }
  mounted() {
    this.relationLinksModule.get();
  }
  beforeDestroy() {
    this.relationLinksModule.destroy();
  }
}
