



























































































































import CardClusterImages from '@/components/cards/form/cluster.images.vue';
import MetaField from '@/components/cards/form/metafield.vue';
import BaseForm from '@/components/common/base-form/index.vue';
import ObjectsInline from '@/components/objects/inline.vue';
import PageLayout from '@/components/page/layout.vue';
import { CardType, createCarCardItemModule, createHumanCardItemModule } from '@/store/cards/cards';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import Vue from 'vue';
import Component from 'vue-class-component';
import { RawLocation } from 'vue-router';
import carsFormSchema from './cars.form.schema';
import humansFormSchema from './humans.form.schema';
import CardAttachments from '@/components/cards/attachments/index.vue';
import CardMonitoringItem from '@/components/cards/monitoring.item.vue';
import RelationList from '@/components/cards/relations/relation-list.vue';
import { Watch } from 'vue-property-decorator';
import { cardBatchModule } from '@/store';
import _ from '@/apps/common/lodash';
import ConfirmButton from '@/components/confirm/confirm-button.vue';
import PuppeteerSender from '@/components/puppeteer/sender/index.vue';
import PuppeteerDailyEvents from '@/components/puppeteer/daily-events/index.vue';
import qs from 'qs';

const CardTabs = {
  General: 'general',
  ClusterImages: 'cluster_images',
  PuppeteerDaily: 'puppeteer_daily',
  Connections: 'connections'
};

const Change = 'change',
  Delete = 'delete';

const CardTabsList = [CardTabs.General, CardTabs.ClusterImages, CardTabs.Connections];

@Component({
  name: 'card-form',
  components: {
    RelationList,
    CardAttachments,
    CardClusterImages,
    PageLayout,
    BaseForm,
    ObjectsInline,
    MetaField,
    ConfirmButton,
    CardMonitoringItem,
    PuppeteerSender,
    PuppeteerDailyEvents
  }
})
export default class CardForm extends Vue {
  edit = true;
  tab = CardTabs.General;
  isLoading = false;
  showAddMonitoringDialog = false;

  private readonly module = selectCardItemStoreModuleFactory(this.cardType)(this.$store);

  @Watch('$route.path')
  handleRouteChange() {
    this.load();
  }

  get changePermissionName() {
    return this.getPermissionName(Change);
  }
  get deletePermissionName() {
    return this.getPermissionName(Delete);
  }

  get isGeneralTab() {
    return this.tab === CardTabs.General;
  }

  get isPuppeteerTab() {
    return this.tab === CardTabs.PuppeteerDaily;
  }

  get isCreatePage() {
    return !this.$route.params.id;
  }

  get isClusterImagesTab() {
    return this.tab === CardTabs.ClusterImages;
  }

  get isConnectionsTab() {
    return this.tab === CardTabs.Connections;
  }

  getEventsFilterPath(objectsType) {
    return `#/events/${objectsType}/filter/limit=20&matched_card=${this.state.item?.id}`;
  }

  getSearchPath(objectsType, targetType) {
    const filter = Object.assign({}, this.$store.state.search.filter.empty);
    filter.sourceCardId = this.state.item?.id;
    filter.sourceType = 'card';
    filter.targetType = targetType;
    filter.objectsType = objectsType;

    return '#/search/' + qs.stringify(filter);
  }

  getSingleObjectForm(v) {
    return ObjectsTypeSingleForm[v];
  }

  get globalConfig() {
    return this.$store.state.config;
  }

  get metaData() {
    return this.cardType === CardType.Human ? this.$store.state.config.human_card : this.$store.state.config.car_card;
  }

  get filteredCardTabsList() {
    return CardTabsList.filter(
      (v) =>
        !(!this.state.item!.id && (v === CardTabs.ClusterImages || v === CardTabs.Connections)) &&
        !(!this.$hasPermission('ffsecurity.view_relation') && v === CardTabs.Connections)
    );
  }

  get tabs() {
    const metaFieldTabs = (this.metaData?.tabs || []).filter((v) => CardTabsList.indexOf(v.name) === -1);
    const puppeteerTabs = this.$store.getters.puppeteer && this.cardType !== CardType.Car ? [{ name: 'puppeteer_daily', i18n: 'location' }] : [];
    const result = this.filteredCardTabsList.map((v) => ({ name: v, i18n: v })).concat(metaFieldTabs, puppeteerTabs);
    return result;
  }

  get tabMetaFields() {
    const metaFields = this.metaData?.items || [];
    return metaFields.filter((v) => {
      const metaFieldTab = v.tab || CardTabs.General;
      return metaFieldTab === this.tab;
    });
  }

  get supportedObjects(): string[] {
    return cardBatchModule.enabledObjectsByType(this.cardType);
  }

  get state() {
    return this.module.state;
  }

  get schema() {
    switch (this.cardType) {
      case CardType.Car:
        return carsFormSchema;
      case CardType.Human:
      default:
        return humansFormSchema;
    }
  }

  get modifiedSchemaFields() {
    return this.schema.fields.filter((field) => !(this.isCreatePage && (field.prop === 'modified_date' || field.prop === 'created_date')));
  }

  get cardType(): CardType {
    return this.$route.meta?.type || CardType.Human;
  }

  get isMonitoringAvailable() {
    return this.isGeneralTab && !this.isCreatePage && this.$store.getters.puppeteer && this.cardType !== CardType.Car;
  }

  created() {
    this.load();
  }
  load() {
    const { item, id } = this.$route.params;
    if (item) {
      this.state.setItemsState(item as any);
    } else if (id) {
      this.state.get(id);
    }

    if (this.metaData) this.$store.dispatch(this.$store.state.dicts.Action.Get);
  }

  beforeDestroy() {
    this.module.unreg();
  }

  createObjectHandler() {}

  loadObjectHandler(value) {}

  getPermissionName(action) {
    return 'ffsecurity.' + action + '_' + this.cardType + 'card';
  }

  async deleteHandler() {
    this.isLoading = true;
    try {
      await this.state.delete(this.state.item!.id);
      this.$showSuccess({ message: this.$tf('success') });
      this.$router.push({ name: 'humansCards' });
    } catch (e) {
      this.$showError(new Error(e));
    }
    this.isLoading = false;
  }

  async saveHandler() {
    this.isLoading = true;
    const isFormValid = await (this.$refs.baseForm as BaseForm)?.validate();
    const { item } = this.state;
    let result;

    if (isFormValid) {
      try {
        if (item?.id) {
          result = await this.state.update(this.state.changes);
        } else {
          result = await this.state.create();
          await this.$router.replace(this.state.getItemRoute() as RawLocation);
        }
        this.$notify({ type: 'success', message: this.$tf('action | success') });
      } catch (e) {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      } finally {
        this.isLoading = false;
      }
    } else {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: this.$tf('error.form.validation') } }) });
      this.isLoading = false;
    }

    return result;
  }

  async removeFromMonitoring() {
    await this.$store.dispatch('removeFromMonitoring', { cardIDs: [this.state.item!.id], vueContext: this });
    this.$refs.cardMonitoring?.load();
  }

  externalSearch() {
    this.$store.state.dialog.puppeteer_search.case_face = null;
    this.$store.state.dialog.puppeteer_search.card = this.state.item!.id;
    this.$store.state.dialog.puppeteer_search.item = null;
    this.$store.state.dialog.puppeteer_search.enabled = true;
  }

  addToMonitoringHandler() {
    this.showAddMonitoringDialog = false;
    setTimeout(() => {
      this.$refs.cardMonitoring?.load();
    }, 1000);
  }
}

function selectCardItemStoreModuleFactory(type: CardType) {
  if (type === CardType.Car) {
    return createCarCardItemModule;
  } else if (type === CardType.Human) {
    return createHumanCardItemModule;
  }
  throw new Error(`Unsupported card's type: "${type}".`);
}
